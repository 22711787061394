<template>
  <div
    class="d-flex align-items-center cursor-pointer"
    @click="$router.push({ name: 'minha.conta' })"
  >
    <div class="d-flex flex-column align-items-end mr-1">
      <h6 class="mb-0">{{ $store.state.auth.userData.nome }}</h6>
      <span>{{ userSubTitle() }}</span>
    </div>
    <feather-icon icon="UserIcon" size="24" />
  </div>
</template>

<script>
export default {
  name: 'UserData',
  methods: {
    userSubTitle() {
      const subtitle = {
        'Admin::User': 'Administrador',
        'Cedente::User': 'Parceiro',
      }
      return subtitle[this.$store.state.auth.userData.type]
    },
  },
}
</script>
